import { useEffect } from "react";
import { useRouter } from "next/router";

/**
 * When loaded on a page, it forces a full page refresh when
 * the user navigates back to the page via the router.
 * Use when a page's accuracy depends on server-side
 * data, and therefore cannot rely on the browser's built-in
 * bfcache.
 */
export function useRouterRefreshSSR() {
  const router = useRouter();
  return useEffect(() => {
    router.beforePopState(({ as }) => {
      window.location.href = as;
      return true;
    });
  }, [router]);
}
