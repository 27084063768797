import { EmailInput, PasswordInput, Icon, Button } from "components";
import request from "lib/request";
import { FormEvent, useRef, useState } from "react";
import { toast } from "lib/toast";
import { useMountEffect } from "hooks/useMountEffect";
import { config } from "config";
import { useRouter } from "next/router";
import { GetServerSidePropsContext } from "next";
import { userRouter } from "lib/auth/user";
import { useAnalytics } from "context/analytics";
import statsig from "service/statsig";
import { useRouterRefreshSSR } from "hooks/useRouterRefreshSSR";

const LoginPage = ({
  logoutMessage,
  orgId,
  disableSignups,
}: {
  logoutMessage?: string;
  orgId: string | undefined | null;
  disableSignups?: boolean;
}) => {
  const { logEvent } = useAnalytics();
  const [loading, setLoading] = useState(false);
  const form = useRef<HTMLFormElement>(null);
  const emailInput = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const defaultEmail = router.query.email ? decodeURIComponent(router.query.email as string) : null;
  const redirect = router.query.redirect as string;
  useRouterRefreshSSR();

  const login = async (event: FormEvent) => {
    event.preventDefault();
    const elements = form.current?.elements as any;

    const json = {
      email: elements.email.value,
      password: elements.password.value,
    };

    if (loading) return;
    setLoading(true); // Set loading manually to revent-double logins
    const res = await request("/api/user/session/login", { method: "POST", json });

    if (res?.complete) {
      logEvent("User Logged In", {
        source: "password",
      });

      // if there is a redirect query param, go there
      if (redirect) {
        window.location.href = redirect;
      } else {
        window.location.href = config.user_home_path;
      }
    } else {
      setLoading(false);
    }
  };

  const resetPassword = async (e) => {
    e.preventDefault();

    const email = (form.current!.elements as any).email.value;
    if (!email.length) {
      router.push("/forgot-password");
    } else {
      if (!emailInput.current?.reportValidity()) {
        return;
      }
      const res = await request("/api/user/password/request", { method: "POST", json: { email } });

      if (res?.complete) {
        toast.success(
          "If an account exists for this email, then a reset email was sent. Please check your email inbox.",
          { autoClose: false }
        );
      }
    }
  };

  useMountEffect(() => {
    if (logoutMessage) toast.info(logoutMessage);
  });

  const ssoRedirectUrl = `/api/auth/google/?type=login${redirect ? `&redirect=${redirect}` : ""}${
    orgId ? `&orgId=${orgId}` : ""
  }`;

  return (
    <div className="Login">
      <div className="container">
        {orgId ? (
          <div className="logos">
            <Icon icon="logo" />
            <span>+</span>
            <img
              alt={orgId || "logo"}
              src={`${config.org_pictures_url}/${orgId}/logo.png`}
              className={orgId === "nj_stfa" || orgId === "stfa" ? "nj-stfa-logo" : ""}
            />
          </div>
        ) : (
          <Icon icon="logo" />
        )}
        <form onSubmit={login} ref={form}>
          <EmailInput
            required
            autoComplete="email"
            id="email"
            data-attr="email"
            defaultValue={defaultEmail ?? ""}
            ref={emailInput}
          />
          <PasswordInput
            required
            autoComplete="current-password"
            id="password"
            data-attr="password"
            defaultValue=""
          />
          <a className="forgot-password" onClick={resetPassword}>
            Forgot Password?
          </a>
          <Button className="wide" formAction="submit" loading={loading} data-attr="log-in">
            Log in
          </Button>
          <div className="divider">
            <div className="line" />
            <p>OR</p>
            <div className="line" />
          </div>
          <Button
            onClick={() => {
              const email = emailInput.current?.value;
              const emailRedirect = `/login/request/${orgId ?? ""}${
                email?.length ? `?email=${email}` : ""
              }`;
              router.push(emailRedirect);
            }}
            iconLeft="Mail"
            className="wide secondary sso"
            type="button"
          >
            Log in with Email
          </Button>
          <Button href={ssoRedirectUrl} iconLeft="LogoGoogle" className="wide secondary sso">
            Log in with Google
          </Button>
          <Button iconLeft="Lock" href="/login/sso" className="wide secondary sso" type="button">
            Log in with SSO
          </Button>
        </form>
        {!disableSignups ? (
          <a className="Button tertiary" href={`/signup${orgId ? `/${orgId}` : ""}`}>
            Sign up instead
          </a>
        ) : (
          <a className="Button tertiary" href={"/waitlist"} data-attr="join-waitlist">
            Join Waitlist
          </a>
        )}
      </div>
    </div>
  );
};

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  // Example : njsbpa.atlas.net redirects to app.atlas.net/signup/njspba
  const hostParts = context.req.headers.host?.split(".") || [];
  if (hostParts.length > 2 && hostParts[0] !== "app") {
    const org = hostParts[0];
    const rootHost = hostParts.slice(1).join(".");
    const protocol = context.req.headers["x-forwarded-proto"]?.includes("https") ? "https" : "http";
    let destination = `${protocol}://app.${rootHost}/signup/${org}`;
    if (Object.keys(context.query).length > 0)
      destination += "?" + new URLSearchParams(context.query as any).toString();
    return { redirect: { destination, permanent: true } };
  }

  // Re-route if already logged in
  await userRouter(context);

  const disableSignups = await statsig.checkGate(
    { account_id: "" },
    "disable_signups_for_waitlist"
  );

  const logoutMessage = context.req.headers.referer?.includes("/onboard")
    ? "Finish your enrollment later by logging back in."
    : null;

  return { props: { title: "Login", logoutMessage, disableSignups } };
};

export default LoginPage;
